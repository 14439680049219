<template>
	<div class="content">
		<vue-headful
			:title="pageTitle"
		/>
		<!-- <Load v-if="isLoading"></Load> -->
		<loading :active.sync="isLoading"
		         :is-full-page="true"/>
		
		<header :style="cssVarColor">
			<h1>{{ $t('time_line') }}</h1>
		</header>
		
		<div v-if="!isLoading && timelineError" class="error-code">
			{{ $t('no_resources') }}
		</div>
		
		<main v-if="!isLoading && timeline">
			<div class="container">
				<img class="bg-image" :src="timeline.mainImageUrl" alt="">
			</div>
			
			<div v-if="timeline.locale" class="timeline">
				<div class="title">
					<h2 ref="title" @click="readMoreTitle()">
						{{ timeline.locale.name }}
					</h2>
					<div v-if="showReadMoreTitle" class="read-more">
						<span v-if="!isExpandedTitle" ref="read-more-title" @click="readMoreTitle()">{{ $t('read_more') }}</span>
						<span v-else ref="read-less-title" @click="readMoreTitle()">{{ $t('read_less') }}</span>
					</div>
				</div>
				
				<div class="subtitle">
					<h3 ref="subtitle" @click="readMoreSubtitle()">
						{{ timeline.locale.description }}
					</h3>
					<div v-if="showReadMoreSubtitle" class="read-more">
						<span v-if="!isExpandedSubtitle" ref="read-more-subtitle" @click="readMoreSubtitle()">{{ $t('read_more') }}</span>
						<span v-else ref="read-less-subtitle" @click="readMoreSubtitle()">{{ $t('read_less') }}</span>
					</div>
				</div>
				
				<div v-if="timeline.minutes" class="info">
					<i class="mm-icon mm-icon-time white"/>
					{{ ($t('duration')).replace('%1$d', timeline.minutes) }}
				</div>
				<div v-if="timeline.artworkCount" class="info">
					<i class="mm-icon mm-icon-picture white"/>
					{{ ($t('artworks_included')).replace('%1$d', timeline.artworkCount) }}
				</div>
				
				<div class="audio-container">
					<audio-player v-if="timeline.locale.mainAudioUrl"
					              :key="timeline.idTimeline"
					              class="audio"
					              :file="timeline.locale.mainAudioUrl"
					              :auto-play="false"
					              :hide-controls-until-play="true"/>
				</div>
			</div>
		</main>
		
		<footer :style="footerCSSVars">
			<div v-if="!isLoading && timeline" class="timeline-buttons">
				<button class="button-start" :style="cssVarColor" @click="goToTimeline()">
					{{ $t('start_route') }}
					<i class="mm-icon mm-icon-arrow-right big"/>
				</button>
			</div>
			<div class="footer-buttons">
				<button class="button-white" @click="goBack()">
					<i class="mm-icon mm-icon-arrow-back big"/>
				</button>
				<button v-if="museum.recognitionAvailable" class="button-recognition" @click="goToRecognition()">
					<img v-if="customization && customization.recognitionIconUrl" class="recognition-icon" :src="customization.recognitionIconUrl" alt="">
					<img v-else class="recognition-icon" src="@/assets/icons/pointplay.png" alt="">
				</button>
			</div>
		</footer>
	</div>
</template>

<script>
const {debug} = require("@/misc/debug");
import * as mutationTypes from "@/store/mutation-types";
import { mapActions, mapState } from "vuex";
import Loading from "vue-loading-overlay";
import 'vue-loading-overlay/dist/vue-loading.css';
import AudioPlayer from "@/components/AudioPlayer";
import {setFullScreen} from "@/store/helpers";
// import Load from '../../components/Load.vue';

export default {
	name: "TimelineList",
	
	components: {
		AudioPlayer,
		// Load,
		Loading,
	},
	
	data() {
		return {
			encrypted: this.$route.params.idMuseum,
			idMuseum: "0",
			showReadMoreTitle: false,
			isExpandedTitle: false,
			showReadMoreSubtitle: false,
			isExpandedSubtitle: false,
			sectionTimeline: null,
			keyboardCustomization: null,
		};
	},
	
	computed: {
		...mapState({
			museum: state => state.museum.museum,
			customization: state => state.museum.customization,
			isLoading: state => state.app.isLoading,
			isNetworkOnline: state => state.app.isNetworkOnline,
			timeline: state => state.timeline.timeline,
			timelineError: state => state.timeline.timelineError,
			navigationSections: state => state.navigationSection.navigationSections,
			navigationSectionsError: state => state.navigationSection.navigationSectionsError,
		}),
		
		pageTitle() {
			let locationName = this.$i18n.t('time_line');
			
			let museumName = (this.museum?.name)
				? this.museum.name
				: this.museum?.locale?.name
					? this.museum.locale.name
					: "MuseumMate";
			
			return locationName + ' | ' + museumName;
		},
		
		cssVarColor() {
			let bgColor = null;
			let textColor = null;
			
			if(!this.isLoading) {
				bgColor = (this.sectionTimeline?.active && this.sectionTimeline.lineColor) ? this.sectionTimeline.lineColor : '#94af49';
				
				textColor = (this.sectionTimeline?.active && this.sectionTimeline.arrowColor) ? this.sectionTimeline.arrowColor : '#ffffff';
			}
			
			return {
				'--bg-color': bgColor,
				'--text-color': textColor
			};
		},
		
		footerCSSVars() {
			return {
				'--recognition-color': (this.customization?.mainButtonsCustomizationEnabled) ? this.customization.recognitionColor : '#f06140',
			};
		},
	},
	
	created() {
		debug.log("[TimelineList] Creating view");
		this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, true);
		
		try {
			let decryption = (atob(this.encrypted));
			this.idMuseum = decryption.split('-')[0];
			
			this.loadMuseum(this.idMuseum)
				.then(async () => {
					await this.getTimeline();
					this.getNavigationSections();
					this.sectionTimeline = this.navigationSections.find((s) => s.sectionType === 'TIMELINE');
					if(this.museum.keyboardCustomization && this.museum.keyboardCustomization.active) {
						this.keyboardCustomization = this.museum.keyboardCustomization;
					}
				})
				.finally(async () => {
					await this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, false);
					this.shouldShowReadMore();
				});
		} catch(e) {
			if (process.env.VUE_APP_DEBUG_MODE == "true") console.error("Error deciphering: ", e);
			this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, false);
		}
	},
	
	methods: {
		...mapActions('app', ['loadMuseum']),
		...mapActions('timeline', ['getTimeline']),
		...mapActions('navigationSection', ['getNavigationSections']),
		
		setFullScreen(to = null) {
			setFullScreen();
			if(to) {
				this.$router.push(to);
			}
		},
		
		shouldShowReadMore() {
			// 58 is the height of two lines of text in the title
			this.showReadMoreTitle = (this.$refs.title)
				? this.$refs.title?.offsetHeight > 58
				: false;
			if(this.showReadMoreTitle) {
				this.$refs.title.classList.add('text-overflow');
			}
			
			// 57 is the height of three lines of text in the subtitle
			this.showReadMoreSubtitle = (this.$refs.subtitle)
				? this.$refs.subtitle?.offsetHeight > 57
				: false;
			if(this.showReadMoreSubtitle) {
				this.$refs.subtitle.classList.add('text-overflow');
			}
		},
		
		readMoreTitle() {
			setFullScreen();
			this.isExpandedTitle = !this.isExpandedTitle;
			if(this.isExpandedTitle) {
				this.$refs.title.classList.remove('text-overflow');
			} else {
				this.$refs.title.classList.add('text-overflow');
			}
		},
		
		readMoreSubtitle() {
			setFullScreen();
			this.isExpandedSubtitle = !this.isExpandedSubtitle;
			if(this.isExpandedSubtitle) {
				this.$refs.subtitle.classList.remove('text-overflow');
			} else {
				this.$refs.subtitle.classList.add('text-overflow');
			}
		},
		
		goToTimeline() {
			setFullScreen();
			this.$router.push({ name: 'Timeline', params: { idMuseum: this.encrypted, idTimeline: String(this.timeline.idTimeline) } });
		},
		
		goToRecognition() {
			setFullScreen();
			this.$router.push({name: 'Recognition', params: { idMuseum: this.encrypted} });
		},
		
		goBack() {
			setFullScreen();
			this.$router.push({ name: 'Options', params: { idMuseum: this.encrypted } });
		},
	},
};
</script>

<style lang="scss" scoped>
@import '../../theme/colors';

.content {
	height: 100%;
	
	.error-code {
		padding: 80px 20px 100px 20px;
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
		font-size: 22px;
	}
	
	header {
		position: fixed;
		top: 0;
		height: 70px;
		width: 100%;
		background-color: var(--bg-color);
		padding: 20px;
		z-index: 1;
		
		h1 {
			color: var(--text-color);
			font-size: 24px;
			font-weight: normal;
		}
	}
	
	main {
		height: 100%;
		
		.container {
			overflow: hidden;
			position: fixed;
			width: 100%;
			height: 100%;
			z-index: -1;
			
			.bg-image {
				width: 100%;
				height: 100%;
				filter: brightness(60%);
				object-fit: cover;
			}
		}
		
		.timeline {
			padding: 90px 20px 200px;
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			overflow-y: scroll;
			
			.title {
				margin-bottom: 10px;
				color: white;
				
				h2 {
					overflow: hidden;
					color: white;
					font-size: 24px;
					font-weight: normal;
				}
				
				.read-more {
					text-decoration: underline;
				}
			}
			
			.subtitle {
				margin-bottom: 10px;
				color: white;
				
				h3 {
					overflow: hidden;
					color: white;
					font-weight: normal;
				}
				
				.read-more {
					text-decoration: underline;
				}
			}
			
			.text-overflow {
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
			}
			
			.info {
				display: flex;
				align-items: center;
				color: white;
				margin-bottom: 10px;
				
				i {
					margin-right: 10px;
					margin-bottom: -5px;
				}
			}
			
			.audio-container {
				flex: 2;
				display: flex;
				align-items: center;
				justify-content: center;
				
				.audio {
					width: 100%;
				}
			}
		}
	}
	
	footer {
		position: fixed;
		bottom: 0;
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		justify-items: center;
		
		.timeline-buttons {
			width: 100%;
			height: 90px;
			display: flex;
			justify-content: flex-end;
			align-items: center;
			
			.button-start {
				border: none;
				text-transform: uppercase;
				background-color: var(--bg-color);
				color: var(--text-color);
				padding: 10px;
				width: 50%;
				height: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
				
				i {
					margin-left: 15px;
				}
			}
		}
		
		.footer-buttons {
			width: 100%;
			height: 90px;
			display: flex;
			justify-content: center;
			
			button {
				width: 100%;
				border: none;
				text-transform: uppercase;
				display: flex;
				justify-content: center;
				align-items: center;
				
				&.button-white {
					background-color: white;
					color: black;
				}
				
				&.button-recognition {
					color: white;
					background-color: var(--recognition-color);
					
					.recognition-icon {
						max-width: 60%;
						max-height: 80%;
					}
				}
			}
		}
	}
}
</style>
